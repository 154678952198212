import React, { useContext, useEffect, useState } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import API from "../../utils/API";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import classes from "./CseAttendance.module.scss";
import { LargeContainer } from "../../utils/styled-components-library";
import moment from "moment-timezone";
import { UserContext } from "../../App";
import CustomButton from "../../components/buttons/CustomButton";
import { toast } from "react-toastify";
moment.locale("fr");

const Table = styled.table`
  margin: 0 auto;
  margin-bottom: 55px;
  width: 100%;
`;

const Td = styled.td`
  text-align: left;
  padding: 16px 8px;
`;

const ProVoteTabs = withStyles({
  indicator: {
    backgroundColor: "#1D529C",
  },
})(Tabs);

const ProVoteTab = withStyles((theme) => ({
  root: {
    "&$selected": {
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`voters-list-tab-panel-${index}`}
      aria-labelledby={`voters-list-tab-${index}`}
      className={classes.TabPanel}
    >
      {children}
    </div>
  );
};

const CollegeTable = ({ college, cse }: { college: any; cse: any }) => {
  const data = Object.values(college);

  return data.map((election) => {
    return (
      <Table>
        <thead>
          <tr>
            <th className={classes.TableHeader}>Election</th>
            <th className={classes.TableHeader}>Nombre d'inscrits</th>
            <th className={classes.TableHeader}>Nombre de votants</th>
            <th className={classes.TableHeader}>Nombre de votes blancs</th>
            <th className={classes.TableHeader}>Nombre de votes valables</th>
            <th className={classes.TableHeader}>Taux de participation</th>
            <th className={classes.TableHeader}>Quorum</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{election.type}</td>
            <td>{election.vote_expected_number}</td>
            <td>{election.vote_total}</td>
            <td>{cse.isValidKey ? election.vote_blank : "-"}</td>
            <td>{cse.isValidKey ? election.vote_eligible : "-"}</td>
            <td>{election.vote_percentage_string}</td>
            <td>
              {cse.isValidKey ? `${election.quorum_percentage}` : ""}
              {cse.isValidKey ? (election.quorum_reached ? "(Oui)" : "(Non)") : "-"}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  });
};

const CollegeVoter = (voters) => {
  const data = Object.values(voters);
  const voterHasVoted = data.filter((x) => x.titular || x.substitute);
  return (
    <>
      {(voterHasVoted.length && (
        <Table>
          <thead>
            <tr>
              <th className={classes.TableHeader} style={{ textAlign: "left" }}>
                Nom
              </th>
              <th className={classes.TableHeader} style={{ textAlign: "left" }}>
                Prénom
              </th>
              <th className={classes.TableHeader} style={{ textAlign: "left" }}>
                Titulaire
              </th>
              <th className={classes.TableHeader} style={{ textAlign: "left" }}>
                Suppléant
              </th>
            </tr>
          </thead>

          <tbody>
            {voterHasVoted.map((voter, index) => {
              const titular = voter.titular
                ? moment.utc(voter.titular).tz("Europe/Paris").format("dddd DD MMM [à] HH[:]mm")
                : null;
              const substitue = voter.substitute
                ? moment.utc(voter.substitute).tz("Europe/Paris").format("dddd DD MMM [à] HH[:]mm")
                : null;
              return (
                <tr style={index % 2 !== 0 ? { backgroundColor: "#dee3ed" } : {}}>
                  <Td>{voter.cseVoterLastname}</Td>
                  <Td>{voter.cseVoterFirstname}</Td>
                  <Td>{titular}</Td>
                  <Td>{substitue}</Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )) ||
        null}
    </>
  );
};

const CollegeTab = ({
  colleges,
  cse,
  userPoollingStation,
  result,
}: {
  colleges: any;
  cse: any;
  result: boolean;
}) => {
  let [selectedTab, setSelectedTab] = useState(0);
  const [isSubmitting, setIsSubmmiting] = useState(false);

  const createTabProps = (index) => {
    return {
      id: `college-tab-${index}`,
      "aria-controls": `voters-list-tab-panel-${index}`,
    };
  };

  const onTabChangeHandler = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const data = Object.values(colleges).filter((college) => {
    if (!userPoollingStation || !college.pollingStation) {
      return true;
    }
    return userPoollingStation.includes(college.pollingStation.pollingStationId) ||
      userPoollingStation.includes(-1)
      ? true
      : false;
  });

  const isCollegeFull = (college) =>
    college.data.map((election) => {
      return election.vote_percentage === 1 ? true : false;
    });

  return (
    <>
      <ProVoteTabs
        value={selectedTab}
        onChange={onTabChangeHandler}
        aria-label="college tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {data.map((college, index) => {
          if (
            userPoollingStation.includes(-1) ||
            userPoollingStation.includes(college.pollingStation.pollingStationId)
          ) {
            return <ProVoteTab label={college.name} {...createTabProps(index)} />;
          } else {
            <ProVoteTab label={"Nop"} {...createTabProps(index)} />;
          }
        })}
      </ProVoteTabs>
      {data.map((college, index) => {
        let canClosePolling = true;
        isCollegeFull(college).forEach((isFull) => {
          if (!isFull) canClosePolling = false;
        });
        return (
          <TabPanel value={selectedTab} index={index}>
            <div>
              <CustomButton
                style={{ margin: 5 }}
                disabled={
                  !canClosePolling ||
                  (college.pollingStation && college.pollingStation.isClosed === 1) ||
                  !college.pollingStation ||
                  isSubmitting
                }
                onClick={() => {
                  setIsSubmmiting(true);
                  API.post(
                    "/cseRouteCRUD",
                    {
                      pollingStationId: college.pollingStation.pollingStationId,
                      module: "attendance",
                      path: "/closePollingStation",
                    },
                    (data: { success: boolean }) => {
                      setIsSubmmiting(false);
                      if (data.success) {
                        toast.success("Le bureau de vote a été clôturé");
                      } else {
                        toast.error("Le bureau n'a pa pu être clôturé");
                      }
                    }
                  );
                }}
              >
                <>
                  {college.pollingStation && college.pollingStation.isClosed
                    ? `Le bureau de vote ${college.name} est clos`
                    : `Clôturer le bureau de vote ${college.name}`}
                </>
              </CustomButton>
            </div>

            <CollegeTable college={college.data} cse={cse} />
            {(!result && <CollegeVoter {...college.voters} />) || null}
          </TabPanel>
        );
      })}
    </>
  );
};

function downloadBase64FileXLS(contentBase64, fileName) {
  const mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = mediaType;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

function printDiv(body) {
  let mywindow = window.open("", "PRINT", "height=650,width=900,top=100,left=150");

  mywindow?.document.write(body);

  mywindow?.document.close();
  mywindow?.focus();

  mywindow?.print();

  return true;
}

const CseAttendance = ({ match, result }: { match: any; result: boolean }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [cseAttendance, setCseAttendance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext);
  const userType = user.userType;
  const userPoollingStation =
    userType === "client" || result === true
      ? [-1]
      : user?.pollingStations.map((poll) => poll.pollingStationId);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    API.post(
      "/cseRouteCRUD",
      { cseId, module: "attendance", path: "/getAttendance" },
      (data: { success: boolean; data: any }) => {
        setCseAttendance(data.data);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={result ? "resultats" : "emargement"} />
          <CseLayout
            title={result ? "Résultats" : "Émargement"}
            showResults={!isLoading}
            loading={isLoading}
            visible={true}
            additionalActions={
              <>
                {(!result && (
                  <div style={{ gap: "5px", display: "grid" }}>
                    {(userType === "client") && (
                      <CustomButton
                        target="_blank"
                        rel="noreferrer"
                        disabled={isSubmitting}
                        onClick={() => {
                          setIsSubmitting(true);
                          API.post(
                            "/cseRouteCRUD",
                            {
                              cseId,
                              module: "attendanceExportXLS",
                              path: "/xls",
                            },
                            (data: { success: boolean; data: any }) => {
                              setIsSubmitting(false);
                              downloadBase64FileXLS(data.data.body, "Rapport émargement");
                            },
                            () => {}
                          );
                        }}
                      >
                        <>Télécharger la liste au format XLS</>
                      </CustomButton>
                    )}
                    <CustomButton
                      target="_blank"
                      rel="noreferrer"
                      disabled={isSubmitting}
                      onClick={() => {
                        setIsSubmitting(true);
                        API.post(
                          "/cseRouteExport",
                          {
                            cseId,
                            module: "attendanceExportPDF",
                          },
                          (data: { body: any }) => {
                            setIsSubmitting(false);
                            printDiv(data.body);
                          },
                          () => {}
                        );
                      }}
                    >
                      <>Télécharger la liste au format PDF</>
                    </CustomButton>
                  </div>
                )) ||
                  null}
              </>
            }
          >
            <>
              <section>
                {(cse?.isValidKey === 0 && (
                  <>
                    {result
                      ? "Les résultats seront disponibles après le dépouillement."
                      : "Certaines données ne sont accessibles qu'après avoir dépouillé l'urne."}
                  </>
                )) ||
                  null}
              </section>
              {cseAttendance && cseAttendance?.data && !isLoading && (cse.disableParticipationdisplay === 0 || user.userType !== "cseVoter")  && (
                <CollegeTab
                  colleges={cseAttendance.data}
                  cse={cse}
                  userPoollingStation={userPoollingStation}
                  result={result}
                />
              )}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseAttendance;
